import Axios, {CancelTokenSource} from 'axios';
import {FeatureTypes} from '../../../definition/features';
import {store} from '../../../index';
import {config} from '../../../services/config';
import {keycloakService} from '../../../services/keycloak';
import {getUserInfo} from '../../../store/selectors/core.selector';
import {
    getHazardFilters,
    getSafetyEventsFilters,
    getSelectedSite
} from '../../../store/selectors/mining/mining.selectors';
import {TimelineMap} from '../../../store/reducers/mining/IMiningReducerState';

const BASE_URL = process.env.REACT_APP_REST_SERVER as string;
const MINECEPT_ROUTE = process.env.REACT_APP_SERVER_MINECEPT_ROUTE as string;
const BASE_ROUTE = `${BASE_URL}/${MINECEPT_ROUTE}/`;
const obstacleMinSeverity = process.env.REACT_APP_OBSTACLE_MINIMUM_SEVERITY_TO_SHOW as string;
const safetyEventMinSeverity = process.env.REACT_APP_SAFETY_EVENT_MINIMUM_SEVERITY_TO_SHOW as string;

const routes = {
    safetyEvent: `${BASE_ROUTE}safetyevents/`,
    hazard: `${BASE_ROUTE}obstacles/`,
    sites: `${BASE_ROUTE}sites/`,
    archive: 'archive/',
    restore: 'restore/',
    videoExists: 'video/:id/exists',
    videoDownload: 'video/:id',
    dashboardOverall: `${BASE_ROUTE}dashboard/overall`,
    dashboardSite: `${BASE_ROUTE}dashboard/site`
}

export const getHeaders = async () => {
    let headers: any = await keycloakService.authHeaders();
    if (config.isMultiSite) {
        if (!headers) {
            headers = {};
        }
        headers.SITE_ID = getSelectedSite(store.getState());
    }
    return headers;
}

const setArchiveStatus = async (ids, route) => {
    const arr = Array.isArray(ids) ? ids : [ids];
    const userId = getUserInfo(store.getState()).user;
    try {
        await Axios.post(route, arr, {
            headers: await getHeaders(),
            params: {
                manager_id: userId
            }
        });
        return true;
    } catch (e) {
        return false;
    }
};

export const removeSafetyEvents = async (ids) => {
    const route = routes.safetyEvent + routes.archive;
    return setArchiveStatus(ids, route);
};

export const restoreSafetyEvents = async (ids) => {
    const route = routes.safetyEvent + routes.restore;
    return setArchiveStatus(ids, route);
};

export const updateSafetyEvent = async (eventId, remarks) => {
    try {
        await Axios.put(routes.safetyEvent + eventId, {
            remarks: remarks
        }, {
            headers: await getHeaders()
        });
        return true;
    } catch (e) {
        return false;
    }
};

export const removeHazards = async (ids) => {
    const route = routes.hazard + routes.archive;
    return setArchiveStatus(ids, route);
};

export const restoreHazards = async (ids) => {
    const route = routes.hazard + routes.restore;
    return setArchiveStatus(ids, route);
};

export const updateHazard = async (id, remarks) => {
    try {
        await Axios.put(routes.hazard + id, {
            remarks: remarks
        }, {
            headers: await getHeaders()
        });
        return true;
    } catch (e) {
        return false;
    }
};

export const getAllHazards = async () => {
    try {
        const filters = getHazardFilters(store.getState());
        const hazardMaxAge = process.env.REACT_APP_OBSTACLE_MAXIMUM_AGE_TO_SHOW_MINUTES as string;
        return await Axios.get(routes.hazard, {
            headers: await getHeaders(),
            params: {
                all: true,
                hazard_max_age : hazardMaxAge || 24 * 60,
                min_severity : obstacleMinSeverity || 0,
                type: filters.type,
                severity: filters.severity,
                startTime: filters.startTime,
                filterStart: filters.filterStart.toDateString(),
                filterEnd: filters.filterEnd,
                endTime: filters.endTime.toDateString(),
                archive: filters.archive,
                filterVehicles: filters.filterVehicles,
                vehicles: filters.vehicles,
                chunk: 1,
                chunkSize: filters.chunkSize
            }
        });
    } catch (e) {
        return false;
    }
};

export const getAllSafetyEvents = async () => {
    try {
        const filters = getSafetyEventsFilters(store.getState());
         const res = await Axios.get(routes.safetyEvent, {
            headers: await getHeaders(),
            params: {
                all: true,
                min_severity : safetyEventMinSeverity || 0,
                type: filters.type,
                severity: filters.severity,
                startTime: filters.startTime,
                filterStart: filters.filterStart.toDateString(),
                filterEnd: filters.filterEnd,
                endTime: filters.endTime.toDateString(),
                archive: filters.archive,
                filterVehicles: filters.filterVehicles,
                vehicles: filters.vehicles,
                filterHpr: filters.filterHpr,
                chunk: filters.chunk,
                chunkSize: filters.chunkSize
            }
        });

        return res

    } catch (e) {
        return false;
    }
};

export interface IVideo {
    videoUrl: string;
    timeline: TimelineMap
};

export const getIsEventVideoExists = async (id: string, eventType: FeatureTypes, cancelSource?: CancelTokenSource): Promise<IVideo | undefined> => {
    try {
        const baseUrl = eventType === FeatureTypes.hazard ? routes.hazard : routes.safetyEvent;
        const url = `${baseUrl}${routes.videoExists.replace(':id', id)}`;
        const {data} = await Axios.get(url, {
            headers: await getHeaders(),
            cancelToken: cancelSource && cancelSource.token
        });
        return data && {videoUrl: data.videoUrl, timeline: data.timeline};
    } catch (e) {
        return undefined;
    }
}

export const downloadEventVideo = async (id: string, eventType: FeatureTypes) => {
    const baseUrl = eventType === FeatureTypes.hazard ? routes.hazard : routes.safetyEvent;
    const url = `${baseUrl}${routes.videoDownload.replace(':id', id)}`;
    const {data} = await Axios.get(url, {
        responseType: 'blob',
        headers: await getHeaders(),
    });
    return data;
}

export const getSites = async () => {
    try {
        const {data} = await Axios.get(routes.sites,
            {headers: await keycloakService.authHeaders()});
        return data;
    } catch {
        return false;
    }
}

const delay = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, 500);
    })
}

const dashboardBaseQuery = {
    "buckets": 5,
}

export const getDashboardOverallData = async (periodDays: number) => {
    try {
        const timezoneOffset = new Date().getTimezoneOffset();
        const query = {...config.general.dashboard.queryDefaults, timezoneOffset, periodDays};
        const {data} = await Axios.post(routes.dashboardOverall, query, {
            headers: await getHeaders(),
        });
        await delay();
        return data;
    } catch (e) {
        return false;
    }
};

export const getDashboardSiteData = async (site: string, periodDays: number) => {
    try {
        const timezoneOffset = new Date().getTimezoneOffset();
        const query = {...config.general.dashboard.queryDefaults, site, timezoneOffset, periodDays};
        const {data} = await Axios.post(`${routes.dashboardSite}/${site}`, query, {
            headers: await getHeaders(),
        });
        await delay();
        return data;
    } catch (e) {
        return false;
    }
};

export default {
    removeHazards,
    restoreHazards,
    removeSafetyEvents,
    restoreSafetyEvents,
    getSites
}
