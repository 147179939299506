import React, { useEffect, useState } from 'react';
import useStyles from './SortFilterHeader.css';
import { useDispatch, useSelector } from 'react-redux';
import filterSelected from '../../../assets/images/filterSelected.svg';
import SortIcon from '@material-ui/icons/Sort';
import ArchiveIcon from '@material-ui/icons/Archive';
import FilterListIcon from '@material-ui/icons/FilterList';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SortBar from './sortBar/SortBar';
import { SortBy } from '../../../definition/Enums';
import FilterBar from './filterBar/FilterBar';
import MoreBar from './moreBar/MoreBar';
import Emitter from '../../../emitter';
import WhiteCheckbox from '../../../designComponents/WhiteCheckbox';
import { getIsMultiSelected } from '../../../store/selectors/mining/mining.selectors';

interface Iprops{
	display: boolean
	labels:{
		sortTitle:string
		filterTitle: string
		typeLabels: Array<string>
		sortOptions: Array<{label: string, value:SortBy}>
	}
	selectors:{
		data: any
		controls: any
		filter: any
		existingTypes: any
		filtersActivated: any
		areAllSelected: any
		vehicles: any
	}
	actions:{
		setSort: any
		clearFilters: ()=>any
		setFilters: any
		selectAll: any
		unSelectAll: any
	}
}

function SortFilterHeader({display, labels, selectors, actions}:Iprops) {
	const dispatch = useDispatch();
	const controls = useSelector(selectors.controls);
	const isMultiSelected = useSelector(getIsMultiSelected);
	const filtersActivated = useSelector(selectors.filtersActivated);
	const allSelected = useSelector(selectors.areAllSelected);
	const archive = controls.archive;
	const classes = useStyles({display, archive});
	const [sortMenuOpen, setSortMenuOpen] = useState(false);
	const [filterMenuOpen, setFilterMenuOpen] = useState(false);
	const [moreMenuOpen, setMoreMenuOpen] = useState(false);
	const data = useSelector(selectors.data);


	useEffect(()=>{
		setSortMenuOpen(false);
		setFilterMenuOpen(false);
		setMoreMenuOpen(false);
	},[display]);

	useEffect(()=>{
		const listener = ()=> {
			setSortMenuOpen(false);
			setFilterMenuOpen(false);
			setMoreMenuOpen(false);
		};
		Emitter.on('closeSubMenus', listener);
		Emitter.on('mapClicked', listener);
		return () => {
			Emitter.off('closeSubMenus', listener);
			Emitter.off('mapClicked', listener);
		};
	});

	const onSort = (value) => {
		dispatch(actions.setSort(value));
	}

	const toggleSortMenu = ()=>{
		setSortMenuOpen(!sortMenuOpen);
		setFilterMenuOpen(false);
		setMoreMenuOpen(false);
	};

	const toggleFilterMenu = ()=>{
		setFilterMenuOpen(!filterMenuOpen);
		setSortMenuOpen(false);
		setMoreMenuOpen(false);
	};

	const toggleMoreMenu = ()=>{
		setMoreMenuOpen(!moreMenuOpen);
		setFilterMenuOpen(false);
		setSortMenuOpen(false);
	};

	const checkboxAllClicked = () =>{
		if(allSelected){
			dispatch(actions.unSelectAll());
		}else{
			dispatch(actions.selectAll(data));
		}
	}

	return (
			<div className={classes.filterBar}>
				{
					isMultiSelected && <div>
						<WhiteCheckbox checked={allSelected} onClick={checkboxAllClicked}/>
						<span className={classes.selectAllText}>Select All</span>
					</div>
				}
				{<div className={classes.archive}>

				</div>}
				<div  className={classes.filterBarButtons}>
					<div className={classes.sortButton}>
						<SortIcon onClick={toggleSortMenu}/>
						{
							sortMenuOpen && <SortBar currentValue={controls.sortBy} setSort={onSort} options={labels.sortOptions} close={toggleSortMenu} title={labels.sortTitle}/>
						}
					</div>
					<div className={classes.sortButton}>
						{
							filtersActivated ? <img src={filterSelected} onClick={toggleFilterMenu} className={classes.button}/> : <FilterListIcon onClick={toggleFilterMenu}/>
						}
						{
							filterMenuOpen && <FilterBar display={display}  controlsSelector={selectors.controls} close={toggleFilterMenu} title={labels.filterTitle} filterSelector={selectors.filter} existingTypesSelector={selectors.existingTypes} vehiclesSelector={selectors.vehicles} setFilters={actions.setFilters} typeLabels={labels.typeLabels} clearFilters={actions.clearFilters} filtersActivatedSelector={selectors.filtersActivated}/>
						}
					</div>
					<div className={classes.buttonSection}>
						<div className={classes.buttonStyle}>
							<MoreVertIcon onClick={toggleMoreMenu} className={classes.moreButton}/>
						</div>
						{
							// @ts-ignore
							moreMenuOpen && <MoreBar {...{selectors, actions}} />
						}
					</div>
				</div>
			</div>
	);
}

export default SortFilterHeader;
