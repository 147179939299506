import { SortBy } from '../../../../definition/Enums';
import {
	areMoreHazards,
	getAreAllHazardsSelected,
	getAreHazardFiltersActivated,
	getHazardControls,
	getHazardFilters,
	getHazardsForTable,
	getHazardsVehicles,
	getMultiSelectedHazards, getTypesOfExistingHazards,
} from '../../../../store/selectors/mining/mining.selectors';
import {
	archiveMultiHazards,
	clearMultiSelect,
	multiSelectAllHazards, multiUnselectAllHazards,
	resetHazardFilters, restoreMultiHazards,
	setHazardFilters,
	setHazardSort,
	setNewHazardFlag,
	showMoreHazards,
} from '../../../../store/actions/mining.actions';
import { config } from '../../../../services/config';

const sortHazardsOptions = [
	{ label: 'Last Added', value: SortBy.time },
	{ label: 'Hazard Type', value: SortBy.type },
	{ label: 'Hazard Severity', value: SortBy.severity },
	{ label: 'Event Vehicle', value: SortBy.vehicle },
];

const selectors = {
	data: getHazardsForTable,
	controls: getHazardControls,
	filter: getHazardFilters,
	existingTypes: getTypesOfExistingHazards,
	filtersActivated: getAreHazardFiltersActivated,
	areAllSelected: getAreAllHazardsSelected,
	multiSelected: getMultiSelectedHazards,
	vehicles: getHazardsVehicles,
	more: areMoreHazards,
};

const actions = {
	setSort: setHazardSort,
	removeNewMessageAlert: setNewHazardFlag(false),
	clearFilters: resetHazardFilters,
	setFilters: setHazardFilters,
	selectAll: multiSelectAllHazards,
	unSelectAll: multiUnselectAllHazards,
	clearMultiSelect,
	archiveMulti: archiveMultiHazards,
	restoreMulti: restoreMultiHazards,
	showMoreEntities: showMoreHazards,
};

const getMenuData = () => {
	const labels = {
		title: 'Hazards',
		sortTitle: 'Sort Hazards',
		filterTitle: 'Filter Hazards',
		typeLabels: config.hazardAndSafetyLabels.hazards,
		sortOptions: sortHazardsOptions,
	};
	return { actions, labels, selectors };
};

export default getMenuData;
