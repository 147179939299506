import {Subject} from 'rxjs';
import {createSelector} from 'reselect';
import {selector$} from '../../store';
import {getHazardLabel, getSafetyEventLabel} from '../../../core/services/minecept/auxilary';
import {FeatureTypes} from '../../../definition/features';
import {SortBy} from '../../../definition/Enums';
import {getSafetyEventIcon} from '../../../core/services/icons/safetyEventIcons';
import {getHazardIcon} from "../../../core/services/icons/obstacleIcons";
import {IFilters, IMineceptDashboard, IMineceptStatus, IObstacle} from '../../reducers/mining/IMiningReducerState';
import {isSeverityZeroWarn} from '../../../definition/severity';
import {getAreFiltersActivated, getFilteredEntities, getSortedEntities} from "../../reducers/mining/entities";

export const getIsFalling = (state) => state.mining.isFalling;
export const getIsGoingReverse = (state) => state.mining.isGoingReverse;
export const getIsBermVisible = (state) => state.mining.isBermVisible;
export const getReverseData = (state) => state.mining.reverseData;
export const getAlertSwitches = (state) => state.mining.alertSwitches;
export const getObstacleAlerts = (state) => state.mining.obstacles;

export const getIsSevereObstacleAlert = (state) => !!state.mining.obstacles.filter(obstacle => obstacle.severity > 2).length;
export const getObstacleTooltipData = (state) => state.mining.tooltips.obstacle;
export const getVehicleTooltipData = (state) => state.mining.tooltips.vehicle;
export const getWarningSectors = (state) => state.mining.warningSectors;
export const getMineceptStatus = (state) => state.mining.mineceptStatus;
export const getSafetyEvents = (state) => state.mining.safetyEvents;
export const getHazards = (state) => state.mining.hazards;
export const getSelectedFeatureId = (state) => state.mining.selected;
export const getControls = (state) => state.mining.controls;
export const getMultiSelected = (state) => state.mining.multiSelect;
const getFlags = (state) => state.mining.flags;
export const getAvailableVehicles = (state) => state.mining.multiSite.availableVehicles;

export const getIsMultiSelected = createSelector([getMultiSelected], (multiSelect) => !!multiSelect.length);
export const getSpeedAlert = createSelector([getReverseData], (reverseData) => reverseData.SpeedAlert);
export const getAngleAlert = createSelector([getReverseData], (reverseData) => reverseData.ApproachAngleAlert);
export const getBermAlert = createSelector([getReverseData], (reverseData) => reverseData.MidAxleHeightAlert);

export const getTypesOfExistingHazards = createSelector([getHazards], (arr) => arr.reduce((prev, curr) => {
	prev[curr.obstacle_type] = true;
	return prev;
}, {}));
export const getTypesOfExistingEvents = createSelector([getSafetyEvents], (arr) => arr.reduce((prev, curr) => {
	prev[curr.type_of_event] = true;
	return prev;
}, {}));

export const getSafetyEventsControls = createSelector([getControls], controls => controls.safetyEvents);
export const getSafetyEventsSortBy = createSelector([getSafetyEventsControls], controls => controls.sortBy);
export const getSafetyEventsArchive = createSelector([getSafetyEventsControls], controls => controls.archive);
export const getSafetyEventsFilters = createSelector([getSafetyEventsControls], controls => controls.filters);
export const getHazardControls = createSelector([getControls], controls => controls.hazards);
export const getHazardSortBy = createSelector([getHazardControls], controls => controls.sortBy);
export const getHazardArchive = createSelector([getHazardControls], controls => controls.archive);
export const getHazardFilters = createSelector([getHazardControls], controls => controls.filters);
export const getAreEventFiltersActivated = getAreFiltersActivated(getSafetyEventsFilters);
export const getAreHazardFiltersActivated = getAreFiltersActivated(getHazardFilters);
export const getFilteredSafetyEvents = getFilteredEntities(getSafetyEvents, getSafetyEventsFilters);
export const getFilteredHazards = getFilteredEntities(getHazards, getHazardFilters);
export const getSafetyEventIds = createSelector([getFilteredSafetyEvents], (events) => events.map(event => event.id));
export const getMultiSelectedSafetyEvents = createSelector([getMultiSelected, getSafetyEventIds], (multiSelect, eventIds) => multiSelect.filter(id => eventIds.includes(id)));
export const getAreAllSafetyEventsSelected = createSelector([getMultiSelectedSafetyEvents, getSafetyEventIds], (multiSelect, eventIds) => eventIds.every(id => multiSelect.includes(id)));

export const getSlicedEntities = (getFilteredEntities, getEntitiesFilters) => createSelector(
	[getFilteredEntities, getEntitiesFilters],
	(filteredEntities: [any], filters: any) => filteredEntities//.slice(0, filters.chunk* filters.chunkSize)
);

const areMoreEntities = (getFilteredEntities, getSlicedEntities) => createSelector(
	[getFilteredEntities, getSlicedEntities],
	(filteredEntities: [any], slicedEntities: [any]) => filteredEntities.length >= slicedEntities.length
);

export const getSlicedSafetyEvents = getSlicedEntities(getFilteredSafetyEvents, getSafetyEventsFilters);
export const getSlicedHazards = getSlicedEntities(getFilteredHazards, getHazardFilters);
export const areMoreHazards = (state) => state.mining.controls.hazards.isMore;
export const areMoreSafetyEvents =  (state) => state.mining.controls.safetyEvents.isMore;

export const getSortedSafetyEvents = getSortedEntities(getSlicedSafetyEvents, getSafetyEventsSortBy);
export const getSortedHazards = getSortedEntities(getSlicedHazards, getHazardSortBy);
export const getHazardIds = createSelector([getFilteredHazards], (hazards) => hazards.map(hazard => hazard.id));
export const getMultiSelectedHazards = createSelector([getMultiSelected, getHazardIds], (multiSelect, hazardIds) => multiSelect.filter(id => hazardIds.includes(id)));
export const getAreAllHazardsSelected = createSelector([getMultiSelectedHazards, getHazardIds], (multiSelect, hazardIds) => hazardIds.every(id => multiSelect.includes(id)));
export const getSelectedFeature = createSelector(
	[getSelectedFeatureId, getFilteredSafetyEvents, getFilteredHazards],
	(id, safetyEvents, hazards) => {
		if (!id) { return null; }
		const safetyEvent = safetyEvents.find(safetyEvent => safetyEvent.id === id);
		if (safetyEvent) { return {...safetyEvent, featureType: FeatureTypes.safetyEvent} }
		const hazard = hazards.find(hazard => hazard.id === id);
		if (hazard) { return {...hazard, featureType: FeatureTypes.hazard} }
		return null;
	}
);

export const getSafetyEventsForTable = createSelector(
	[getSortedSafetyEvents],
	(safetyEvents) => safetyEvents.map(safetyEvent => {
		const symbol = getSafetyEventIcon(safetyEvent.type_of_event, safetyEvent.severity);
		return {
			id: safetyEvent.id,
			date: safetyEvent.creation_time,
			label: getSafetyEventLabel(safetyEvent.type_of_event, safetyEvent.event_description),
			location: [safetyEvent.longitude, safetyEvent.latitude],
			symbol,
			isActive: safetyEvent.is_active,
			isHpr: safetyEvent.hpr_indication || false
		}
	})
);

export const getSafetyEventsVehicles = createSelector(
	[getSafetyEvents, getAvailableVehicles],
	(safetyEvents, availableVehicles) => {
		const vehiclesFromSafetyEvents = Object.entries(safetyEvents.reduce((agg, curr) => {
			const id = curr.created_by || '';
			if (agg[id]) {
				agg[id] += 1;
			} else {
				agg[id] = 1;
			}
			return agg;
		}, {}))
			.map(([id, count]) => ({id, count}));
		const otherVehicles = availableVehicles.filter(id => !vehiclesFromSafetyEvents.find(x=> x.id===id)).map(name => ({id:name || '', count:0}));
		return vehiclesFromSafetyEvents.concat(otherVehicles)
			.sort((a, b) => a.id.localeCompare(b.id))
	})

export const getHazardsForTable = createSelector(
	[getSortedHazards],
	(hazards) => hazards.map(hazard => {
		const symbol = getHazardIcon(hazard.obstacle_type, hazard.severity);
		return {
			id: hazard.id,
			date: hazard.creation_time,
			label: getHazardLabel(hazard.obstacle_type, hazard.obstacle_description),
			location: [hazard.longitude, hazard.latitude],
			symbol,
			isActive: hazard.is_active,
			isHpr: false
		}
	})
);

export const getHazardsVehicles = createSelector(
	[getHazards, getAvailableVehicles],
	(hazards, availableVehicles) => {
		const vehiclesFromHazards = Object.entries(hazards.reduce((agg, curr) => {
		const id = curr.created_by || '';
		if (agg[id]) {
			agg[id] += 1;
		} else {
			agg[id] = 1;
		}
		return agg;
	}, {}))
		.map(([id, count]) => ({id, count}));
		const otherVehicles = availableVehicles.filter(id => !vehiclesFromHazards.find(x=> x.id===id)).map(name => ({id:name || '', count:0}));
		return vehiclesFromHazards.concat(otherVehicles)
		.sort((a, b) => a.id.localeCompare(b.id))
})

export const getNewSafetyEventFlag = createSelector([getFlags], flags => flags.newSafetyEvent);
export const getNewHazardFlag = createSelector([getFlags], flags => flags.newHazard);
export const getPlayedEvent = (state) => state.mining.playedEvent;

export const getIsGoingReverse$ = (): Subject<boolean> => {
	return selector$('mining.isGoingReverse');
};

export const getMineceptLayers$ = (): Subject<any> => {
	return selector$('mining.mineceptLayers');
};

export const getMineceptStatus$ = (): Subject<IMineceptStatus> => {
	return selector$('mining.mineceptStatus');
};
export const getMineceptStatus_status = (state) => Math.min(state.mining.mineceptStatus.status, 3);
export const getMineceptStatus_label = (state) => state.mining.mineceptStatus.label.trim();
export const getMineceptStatus_text = (state) => state.mining.mineceptStatus.text.trim();

export const getSites = (state) => state.mining.multiSite.sites;
export const getSelectedSite = (state) => state.mining.multiSite.selectedSite;
export const getSelectedSiteName = (state) => {
	const selectedSite = state.mining.multiSite.selectedSite;
	const siteData = state.mining.multiSite.sites.find(x => x.id === selectedSite);
	if (siteData) {
		return siteData.name;
	}
	return undefined;
}

export const getDashboardData = (state): IMineceptDashboard => state.mining.dashboard;
export const getDashboardGraphs = (state) => state.mining.dashboard &&
	state.mining.dashboard.data && state.mining.dashboard.data.graphs;
export const getDashboardRange = (state) => state.mining.dashboard &&
	state.mining.dashboard.data && state.mining.dashboard.data.range;
export const getDashboardPeriod = (state) => state.mining.dashboard &&
	state.mining.dashboard.period;
export const getDashboardSite = (state) => state.mining.dashboard &&
	state.mining.dashboard.site;

export const getMineceptObstacles$ = (): Subject<IObstacle[]> => {
	return selector$('mining.obstacles');
};

export const getReverseData$ = (): any => {
	return selector$('mining.reverseData');
}
export const getVideoSoundOffset = (state) => state.mining.videoSoundOffset;

export const getVideoSoundAudioOn = (state) => state.mining.videoSoundAudioOn;
