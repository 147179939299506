import { SafetyEvent } from '../../../interfaces/SafetyEvent';
import { Dictionary } from '../../../interfaces/Dictionary';
import { Falling, SortBy } from '../../../definition/Enums';
import { Hazard } from '../../../interfaces/Hazards';
import { FeatureTypes } from '../../../definition/features';

export interface IMineceptStatus {
	status: number;
	label: string;
	text: string;
}

export interface ITimelineObstacle {
	Type: number;
	severity: number;
}
export interface ITimeline {
	sectors?: number[];
	obstacles?: ITimelineObstacle[];
}

export type TimelineMap = { [key: number]: ITimeline };

export interface IMineceptVideoEvent {
	id: string;
	type: FeatureTypes;
	videoUrl: string;
	timeline?: TimelineMap;
	name?: string;
}

export interface IMinceptSite {
	id: string;
	name: string;
	center?: number[];
	zoom?: number;
}

export interface IMineceptMultiSite {
	sites: IMinceptSite[];
	selectedSite: string;
	availableVehicles: string[];
}

// TODO refactor to dashboard definitions file
interface DateRange {
	start: string | number;
	end: string | number;
}

export interface IMineceptDashboardOverallSeverityBySite {
	range: DateRange;
	sites: string[];
	severities: number[];
	data: { [key: string]: number[] };
}

export interface IMineceptDashboardOverallEventByType {
	range: DateRange;
	sites: string[];
	eventTypes: number[];
	data: { [key: string]: number };
}

export interface IMineceptDashboardOverallEventsOverTime {
	range: DateRange;
	buckets: DateRange[];
	sites: string[];
	data: { [key: string]: number[] };
}

export interface IMineceptDashboardOverallGraphs {
	severityBySite?: IMineceptDashboardOverallSeverityBySite;
	eventsByType?: IMineceptDashboardOverallEventByType;
	eventsOverTime?: IMineceptDashboardOverallEventsOverTime;
}

export interface IMineceptDashboardSiteEventsBySeverity {
	range: DateRange;
	buckets: DateRange[];
	severities: number[];
	data: { [key: string]: number[] };
}

export interface IMineceptDashboardSiteEventsByType {
	range: DateRange;
	buckets: DateRange[];
	eventTypes: number[];
	data: { [key: string]: number[] };
}

export interface IMineceptDashboardSiteEventsByHour {
	range: DateRange;
	hours: number[];
	eventTypes: number[];
	data: { [key: string]: number };
}

export interface IMineceptDashboardSiteGraphs {
	eventsBySeverity?: IMineceptDashboardSiteEventsBySeverity;
	eventsByType?: IMineceptDashboardSiteEventsByType;
	eventsByHour?: IMineceptDashboardSiteEventsByHour;
}

export interface IMineceptDashboardData {
	range: DateRange;
	buckets: DateRange[];
	graphs: IMineceptDashboardOverallGraphs & IMineceptDashboardSiteGraphs;
}

export interface IMineceptDashboard {
	site: string;
	period: number;
	isLoading: boolean;
	isError: boolean;
	data?: IMineceptDashboardData;
}

export const DASHBOARD_ALL_SITES_OPTION = 'All Sites';

export const DashboardPeriods = {
	Day: 1,
	Week: 7,
	Month: 30,
};

export interface IObstacle {
	severity: number;
	obstacleType: number;
	obstacleDescription: string;
}

export interface IReverseData {
	SpeedAlert: number;
	ApproachAngleAlert: number;
	MidAxleHeightAlert: number;
	SpeedKMH: number;
	DistanceToBermCm: number;
	ApproachAngle: number;
	BermHeightCM: number;
};

export interface AlertSwitches {
	speedAlert: boolean;
	approachAngleAlert: boolean;
	midAxleHeightAlert: boolean;
};

export interface IFilters {
	type: Array<number>;
	severity: Array<number>;
	filterStart: boolean;
	startTime: Date;
	filterEnd: boolean;
	endTime: Date;
	archive: boolean;
	filterVehicles: boolean;
	vehicles: string[];
	filterHpr?: boolean;
	chunk: number;
	chunkSize: number;
};

export interface IMiningReducerState {
	mineceptStatus: IMineceptStatus;
	isFalling: Falling;
	isGoingReverse: boolean;
	isBermVisible: boolean;
	reverseData: IReverseData;
	alertSwitches: AlertSwitches;
	obstacles: Array<IObstacle>;
	videoSoundOffset?: number;
	videoSoundAudioOn?: boolean;
	tooltips: {
		obstacle: {
			isOpen: boolean;
			severity: number;
			obstacleType: number;
			obstacleDescription: string;
			position: number[];
		};
		vehicle?: {
			id: string;
			lastSeen: number;
		};
	};
	selected?: string | null;
	multiSelect: Array<string>;
	safetyEvents: Array<SafetyEvent>;
	hazards: Array<Hazard>;
	warningSectors: number[];
	mineceptLayers: Dictionary<Boolean>;
	flags: {
		newSafetyEvent: boolean;
		newHazard: boolean;
	};
	controls: {
		safetyEvents: {
			sortBy: SortBy;
			isMore: boolean;
			filters: IFilters;
		};
		hazards: {
			sortBy: SortBy;
			isMore: boolean;
			filters: IFilters;
		};
	};
	playedEvent?: IMineceptVideoEvent;
	multiSite: IMineceptMultiSite;
	dashboard: IMineceptDashboard;
}
