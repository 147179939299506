import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import useStyles from './featureCard.css';
import olMapService from '../../core/services/ol-map.service';
import {selectFeature, setEventPlayed} from '../../store/actions/mining.actions';
import {getPlayedEvent, getSelectedFeature, getSelectedSiteName} from '../../store/selectors/mining/mining.selectors';
import closeImage from '../../assets/images/clearBlack.svg';
import locationIcon from '../../assets/images/my_location.svg';
import {FeatureTypes} from '../../definition/features';
import {phraseSafetyEventFeature} from './pharsers/safetyEventPharser';
import {phraseHazardFeature} from './pharsers/hazardPharser';
import {convertWgs84ToWebMercator} from '../../core/services/projections';
import {convertPositionToText} from '../../core/auxilary/formatting';
import {setMapToStaticState} from '../../store/actions/map.actions';
import {TextField} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/free-solid-svg-icons';
import ArchiveIcon from '@material-ui/icons/Archive';
import HistoryIcon from '@material-ui/icons/History';
import PlayIcon from '../../assets/images/video/play.svg';
import DownloadIcon from '../../assets/images/video/download.svg';
import {
	removeHazards,
	restoreHazards,
	removeSafetyEvents,
	updateHazard,
	updateSafetyEvent,
	restoreSafetyEvents,
	downloadEventVideo,
	getIsEventVideoExists,
} from '../../core/services/minecept/mineceptServer';
import {getActiveMenu} from '../../store/selectors/core.selector';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Emitter from '../../emitter';
import axios, {CancelTokenSource} from "axios";
import consts from './consts.json';
import {IVideo} from "../../core/services/minecept/mineceptServer";
import hprIcon from '../../assets/images/hpr.svg';
import {getFileName} from "../../services/videoFileName";

const DESCRIPTION_LENGTH = parseInt(process.env.REACT_APP_DESCRIPTION_LENGTH_TO_SHOW as string, 10);

const closeSubMenus = () => {
	Emitter.emit('closeSubMenus');
};

const FeatureCard = () => {
	const dispatch = useDispatch();
	const feature = useSelector(getSelectedFeature);
	const activeMenu = useSelector(getActiveMenu);
	const selectedSiteName = useSelector(getSelectedSiteName);
	const classes = useStyles({menuOpen: activeMenu !== ''});

	const [description, setDescription] = useState('');
	const descriptionOverflow = description.length > DESCRIPTION_LENGTH;

	const [descriptionBoxOpen, setDescriptionBoxOpen] = useState(false);
	const [isHoveringOnNotes, setHoveringOnNotes] = useState(false);
	const [isSeeMore, setSeeMore] = useState(true);
	const substringDescrption = (desc) => desc.substr(0, DESCRIPTION_LENGTH - 3) + '...'
	const checkDescriptionOverflow = (desc?) => {
		desc = desc ? desc : description;
		return desc.length > DESCRIPTION_LENGTH ? substringDescrption(desc) : desc;
	}
	const [descriptionToShow, setDescriptionToShow] = useState(checkDescriptionOverflow());

	const [videoData,setVideoData]= useState<IVideo | undefined>(undefined);
	const [isCheckingVideoExists, setIsCheckingVideoExists] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const videoExistsTimeoutHandler = useRef<NodeJS.Timeout>();
	const videoExistsCancelSource = useRef<CancelTokenSource>();
	const currentFeatureId = useRef(undefined);

	useEffect(() => {
		closeSubMenus();
	}, [feature]);

	useEffect(() => {
		if (!feature) {
			return;
		}
		if (feature.remarks) {
			setDescription(feature.remarks);
			setDescriptionToShow(checkDescriptionOverflow(feature.remarks));
		} else {
			setDescription('');
			setDescriptionToShow('');
		}
	}, [feature]);

	useEffect(() => {
		if (!feature ||
			activeMenu === '' ||
			(feature.featureType === FeatureTypes.safetyEvent && activeMenu === consts.logs.safetyEventLog) ||
			(feature.featureType === FeatureTypes.hazard && activeMenu === consts.logs.hazardLog)) {
			return;
		}
		dispatch(selectFeature(null));
	}, [activeMenu]);
	useEffect(() => {
		setVideoData(undefined);

		if (videoExistsCancelSource.current) {
			videoExistsCancelSource.current.cancel();
			videoExistsCancelSource.current = undefined;
		}

		if (videoExistsTimeoutHandler.current) {
			clearTimeout(videoExistsTimeoutHandler.current);
			videoExistsTimeoutHandler.current = undefined;
		}

		currentFeatureId.current = feature && feature.id;
		if (!feature || !feature.clip_id) {
			return;
		}
		const checkIfVideoExists = async () => {
			if (currentFeatureId.current !== feature.id) {
				return;
			} 
			videoExistsCancelSource.current = axios.CancelToken.source();
			const videoExist = await getIsEventVideoExists(feature.id, feature.featureType);
			setIsCheckingVideoExists(false);
			videoExistsCancelSource.current = undefined;
			if (videoExist) {
				if (currentFeatureId.current === feature.id) {
					setVideoData({videoUrl:videoExist.videoUrl,timeline:videoExist.timeline});
				}
			} else {
				// TODO get recheck interval from configuration
				videoExistsTimeoutHandler.current = setTimeout(checkIfVideoExists, 5000);
			}
		}
		setIsCheckingVideoExists(true);
		setTimeout(checkIfVideoExists, 250); // delay on porpuse to better UX
	}, [feature]);

	if (!feature) {
		return (<></>);
	}

	let data;
	let restoreMethod;
	let removeMethod;
	let saveMethod;
	let confirmationMessage;
	if (feature.featureType === FeatureTypes.safetyEvent) {
		data = phraseSafetyEventFeature(feature);
		removeMethod = removeSafetyEvents;
		saveMethod = updateSafetyEvent;
		restoreMethod = restoreSafetyEvents;
		confirmationMessage = 'You are about to archive a safety event do you wish to continue?';
	} else if (feature.featureType === FeatureTypes.hazard) {
		data = phraseHazardFeature(feature);
		removeMethod = removeHazards;
		restoreMethod = restoreHazards;
		saveMethod = updateHazard;
		confirmationMessage = 'You are about to archive an obstacle do you wish to continue?';
	} else {
		return (<></>);
	}
	const isPositionValid = feature.is_position_valid === undefined || feature.is_position_valid === true;
	const locationString = isPositionValid && convertPositionToText([feature.longitude, feature.latitude]);
	const goToFeature = () => {
		dispatch(setMapToStaticState());
		const position = convertWgs84ToWebMercator([feature.longitude, feature.latitude]);
		olMapService.setCenter(position);
	};
	const handleDescriptionChange = (event) => {
		setDescription(event.target.value.substr(0, 255));
		setDescriptionToShow(checkDescriptionOverflow());
	};

	const handleRemove = async () => {
		if (!removeMethod) {
			return;
		}

		confirmAlert({
			customUI: ({ onClose }) => (
				<div className={classes.removeBox}>
					<div className={classes.removeTitle}>{confirmationMessage}</div>
					<button onClick={onClose} className={classes.removeCancel}>Cancel</button>
					<button onClick={async () => {
						await removeMethod(feature.id);
						olMapService.clearSelection();
						dispatch(selectFeature());
						onClose();
					}} className={classes.removeConfirm}>Archive
					</button>
				</div>
			),
		});

	};

	const handleRestore = async () => {
		await restoreMethod(feature.id);
	};
	const handleSeeMore = () => {
		setSeeMore(!isSeeMore);
		setDescriptionToShow(isSeeMore ? description : checkDescriptionOverflow());
	}

	const handleSave = async () => {
		if (!saveMethod) {
			return;
		}
		await saveMethod(feature.id, description);
		setDescriptionBoxOpen(false);
	};

	const handleCancelDescription = () => {
		setDescription(feature.remarks || '');
		setDescriptionToShow(checkDescriptionOverflow());
		setDescriptionBoxOpen(false);
		handleDescriptionView(false);
	};
	const handleDescriptionView = (val?) => {
		val !== undefined ?
			setDescriptionBoxOpen(val) : setDescriptionBoxOpen(!descriptionBoxOpen);
		setHoveringOnNotes(false);
	};


	const closeCard = () => {
		olMapService.clearSelection();
		dispatch(selectFeature(null));
	};

	const playVideo = () => {
		if (!videoData || !videoData.videoUrl) {
			return;
		}
		const event = {
			id: feature.id,
			type: feature.featureType,
			videoUrl:videoData && videoData.videoUrl,
			timeline:videoData && videoData.timeline,
			name: getFileName(feature,data,selectedSiteName)
		};
		dispatch(setEventPlayed(event));
	}

	const downloadVideo = async () => {
		if (!videoData || !videoData.videoUrl || isDownloading) {
			return;
		}
		setIsDownloading(true);

		const filename = getFileName(feature, data, selectedSiteName);

		try {
			const blob = await downloadEventVideo(feature.id, feature.featureType);
			const url = window.URL.createObjectURL(new Blob([blob]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${filename}.mp4`);
			link.click();
			window.URL.revokeObjectURL(url);
		} finally {
			setIsDownloading(false);
		}
	}

	const notesTextArea = () => {
		return (<div>
			<div className={classes.fieldTitle}>Notes</div>
			<TextField
				className={classes.description}
				value={description}
				onChange={handleDescriptionChange}
				size='small'
				multiline={true}
				rows={5}
				variant='outlined'
				InputProps={{
					classes: {
						input: classes.descriptionText,
					},
				}}
			/>
			<div className={classes.descriptionControls}>
				<span className={classes.save}
					  onClick={handleSave}>Share</span>
				<span className={classes.cancel}
					  onClick={handleCancelDescription}>Cancel</span>
			</div>
		</div>);
	};

	const notesSection = () => {
		return (
			<div className={classes.dataSection}>
				{!descriptionBoxOpen &&
					<span className={classes.addDescription}
						  onMouseEnter={() => setHoveringOnNotes(true)}
						  onMouseLeave={() => setHoveringOnNotes(false)}
						  onClick={() => handleDescriptionView(true)}
					>
					Notes
						{isHoveringOnNotes && <FontAwesomeIcon
							className={classes.addDescriptionIcon}
							icon={faPen}/>}
				</span>}
				{descriptionBoxOpen && notesTextArea()}
				{!descriptionBoxOpen && descriptionToShow.length > 0 &&
					<div className={classes.descriptionField}>
						{descriptionToShow}
					</div>}
				{
					descriptionOverflow && !descriptionBoxOpen &&
					<div className={classes.descriptionSeeMore}
						 onClick={handleSeeMore}>see {isSeeMore ? 'more' : 'less'}</div>
				}

			</div>
		);
	};
	const controlsSection = () => {
		return (
			<div className={classes.controls}>
				{
					feature.is_active ?
						<span className={classes.control} onClick={handleRemove}>
							<ArchiveIcon style={{fill: '#0f2365', fontSize: 20}}/>
							Archive
						</span>
						:
						<span className={classes.control} onClick={handleRestore}>
							<HistoryIcon style={{fill: '#0f2365', fontSize: 20}}/>
							Restore
						</span>
				}
			</div>
		);
	}

	const clipEvent = () => {
		const downloadVideoClasses = [classes.downloadLink, 
			isDownloading && classes.downloadClicked]
			.filter(x => Boolean(x)).join(' ');
		return (
			feature.clip_id && <div className={classes.dataField}>
				<div className={classes.fieldTitle}>Event clip</div>
				{videoData && videoData.videoUrl &&
					<div className={classes.eventClipValue}>
						<img src={PlayIcon} className={classes.button} onClick={playVideo}/>
						<img src={DownloadIcon} className={downloadVideoClasses} onClick={downloadVideo}/>
					</div>
				}
				{feature.clip_id && (!videoData || !videoData.videoUrl) &&
					(isCheckingVideoExists ?
						<div className={classes.eventClipValue}>Trying to find video...</div> :
						<div className={classes.eventClipValue}>Video does not exist</div>
					)
				}
			</div>
		)
	}

	const dataFieldsSection = () => {
		return (
			<>
				<div className={classes.dataSection}>
					{
						data.fields.map((field, key) => {
							return field.title !== consts.features.eventDetails ?
								<div className={classes.dataField} key={key}>
									<div className={classes.fieldTitle}>{field.title}</div>
									<div className={classes.fieldValue}>{field.value}</div>
								</div> : <></>
						})
					}
					<div className={classes.dataField}>
						<div className={classes.fieldTitle}>Location</div>
						{isPositionValid &&
							<div className={classes.geographicFieldValue} onClick={goToFeature}>
								<div>{locationString}</div>
								<div className={classes.goTo}>
									<img src={locationIcon}/>
									<div>Go</div>
								</div>
							</div>
						}
						{!isPositionValid && <div className={classes.invalidPosition}>Invalid data</div>}
					</div>
					{clipEvent()}
				</div>
				{
					data.fields.find(field => field.title === consts.features.eventDetails) &&
					<div className={classes.dataSection}>
					{data.fields.map((field, key) => {
							return field.title === consts.features.eventDetails ?
								<div className={classes.dataField} key={key}>
									<div className={classes.fieldTitle}>{field.title}</div>
									<div className={classes.fieldValue}>{field.value}</div>
								</div> : <></>
						}
					)}
				</div>}
			</>
		)
			;
	}

	return (
		<div className={classes.root}>
			<img src={closeImage} className={classes.closeButton} onClick={closeCard}/>
			<div className={classes.dataSection}>
				<img src={data.symbol} className={classes.symbol}/>
				<div className={classes.labelAndHpr}>
					<span className={classes.label}>{data.label}</span>
					{feature.hpr_indication && <img className={classes.hprIcon} src={hprIcon}/>}
				</div>
			</div>
			{dataFieldsSection()}
			{notesSection()}
			{controlsSection()}
		</div>
	);
};

export default FeatureCard;
