import {createSelector} from "reselect";
import {IFilters} from "./IMiningReducerState";
import {isSeverityZeroWarn} from "../../../definition/severity";
import {SortBy} from "../../../definition/Enums";

export const getFilteredEntities = (getEntities, getEntityFilters) => createSelector(
    [getEntities, getEntityFilters],
    (entities:[any], filters:IFilters) => {
        /*console.log('before filter', entities)
        const severityArray = new Array(4).fill(false);
        filters.severity.forEach(s => severityArray[s] = true);

        const typeArray = new Array(100).fill(false);
        filters.type.forEach(t => typeArray[t] = true);

        let filtered = entities.filter(entity => {
            if (!severityArray[entity.severity]){return false;}
            const type = entity.type_of_event || entity.obstacle_type
            return !!typeArray[type];
        });

        if (filters.filterStart) {
            const startTime = filters.startTime.getTime() / 1000;
            filtered = filtered.filter(event => event.creation_time > startTime);
        }

        if (filters.filterEnd) {
            const endTime = filters.endTime.getTime() / 1000;
            filtered = filtered.filter(entity =>{
                const time = entity.last_seen_time || entity.creation_time;
                return time < endTime;
            });
        }

        if (!filters.archive) {
            filtered = filtered.filter(x => x.is_active);
        }

        if (filters.filterVehicles) {
            filtered = filtered.filter(x => filters.vehicles.includes(x.created_by || ''));
        }

        if (filters.filterHpr) {
            filtered = filtered.filter(x => x.hpr_indication);
        }
        console.log('filtered',filtered.length,entities.length)
        return filtered;*/
        return entities;
    }
);

export const getAreFiltersActivated = (getFilters) => createSelector([getFilters], (filters:IFilters)=> {
    return  filters.filterStart ||
        filters.filterEnd ||
        filters.severity.length !== (isSeverityZeroWarn ? 4 : 3) ||
        filters.type.length !== 100 ||
        filters.archive ||
        filters.filterVehicles ||
        filters.filterHpr;
});

export const getSortedEntities = (getSlicedEntities, getEntitiesSortBy) => createSelector(
   [getSlicedEntities, getEntitiesSortBy],
   (entities:[any], sortBy) => {
   const allEntities = [...entities];
   const sortedByTime = [...allEntities.sort((a, b) => b.creation_time - a.creation_time)];
   switch (sortBy) {
       case SortBy.time:
           return sortedByTime;
       case SortBy.severity:
           return [...sortedByTime.sort((a, b) => b.severity - a.severity)];
       case SortBy.type:
           return [...sortedByTime.sort((a, b) =>{
              const typeA = a.obstacle_type || a.typeB;
              const typeB = b.obstacle_type || b.typeB;
              return typeB - typeA;
           })];
       case SortBy.vehicle:
            return [...sortedByTime.sort((a, b) => (a.created_by || '').localeCompare(b.created_by || ''))];
       }
        return entities;
});
