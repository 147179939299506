import {getHazards} from "./rest";
import {setIsMoreHazards, setHazards} from "../store/actions/mining.actions";
import {store} from "../index";


export const updateHazardsByParams = async (filter) => {
    await getHazards(filter).then(d => store.dispatch(setHazards(d)));
};

export const isShowMoreHazardsByParams = async (filter) => {
    await getHazards(filter).then(d => store.dispatch(setIsMoreHazards(d.length)));
};

export const isHazardMatchFilter = (hazard, filters) => {
    const {type, severity, startTime, filterStart, filterEnd, endTime, archive, filterVehicles, vehicles} = filters;
    const isTypeMatch = type.includes(x => x === hazard.obstacle_type);
    const isSeverityMatch = severity.includes(x => x === hazard.severity);
    const isStartDateMatch = !filterStart || (Math.floor(startTime.getTime()/1000) <= hazard.creation_time);
    const isEndDateMatch = !filterEnd || (Math.floor(endTime.getTime()/1000) >= hazard.creation_time);
    const isArchiveMatch = archive || hazard.is_active;
    const isVehicleMatch = !filterVehicles || vehicles.includes(x => x === hazard.created_by);
    return isTypeMatch && isSeverityMatch && isStartDateMatch && isEndDateMatch && isArchiveMatch && isVehicleMatch ;
};

export const getMatchingHazards = (hazards, filters) => {
    return hazards.filter( hazard => isHazardMatchFilter(hazard,filters));
};
