import BaseLayer from 'ol/layer/Base';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Map from 'ol/Map';
import View from 'ol/View';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import Select from 'ol/interaction/Select';
import { Coords } from '../../definition/simple-types';
import { IRegisterSensorState } from '../../features/sensors/actions/sensors.actions';
import interpolationService from './interpolation.service';
import mapSourceProvider from './map-source.provider';
import vectorSourceProvider from './vector-source.provider';
import adasLayersService from './layers/adas-layers.service';
import selectedFeatureStyle from './layers/styles/selectedFeatureStyles';
import Draw, {createBox} from 'ol/interaction/Draw';
import drawLayerStyle from './layers/styles/drawLayer-styles';
import { store } from '../../index';
import {
	closeTooltip,
	selectFeature,
	setTooltip,
	setVehicleTooltip,
} from '../../store/actions/mining.actions';
import mineceptVectorLayerProvider from './layers/mineceptVectorLayerProvider';
import { shared } from 'ol/style/IconImageCache';
import profileConfig from '../profiles/profileConfig';
import { FeatureType } from '../../definition/Enums';
import { MineceptLayers } from '../../definition/mineceptLayers';
import { convertExtentWebMercatorToWgs84 } from './projections';
shared.setSize(100);


let vehicleOpen = false;

const closeVehicleCard =()=>{
	if(!vehicleOpen) return;
	store.dispatch(setVehicleTooltip());
};

const featureSelected = (e,map)=>{
	if(!e.selected.length) return closeVehicleCard();
	const feature = e.selected[0];
	const category =feature.values_.category;
	if(category === FeatureType.vehicle){
		vehicleOpen = true;
		return store.dispatch(setVehicleTooltip({
			id:feature.values_.vehicleId,
			lastSeen: feature.values_.lastSeen
		}));
	}
	if(profileConfig().showFullFeatureCard){
		closeVehicleCard();
		store.dispatch(selectFeature(feature.values_.id));
	}else{
		if(category !== FeatureType.obstacle && category !== FeatureType.hazard){
			closeVehicleCard();
			return store.dispatch(closeTooltip());
		}
		const geometry = feature.getGeometry();
		const coordinate = geometry.getCoordinates();
		const position = map.getPixelFromCoordinate(coordinate);
		closeVehicleCard();
		store.dispatch(setTooltip({
			isOpen: true,
			severity: feature.values_.severity,
			obstacleType: feature.values_.obstacleType,
			obstacleDescription: feature.values_.obstacleDescription,
			position
		})as any);
	}
}

class OlMapService {
	map!: Map;

	private select: any;
	private multiSelect: any;
	private _followRouteFlag: boolean = false;
	private _reverseViewFlag: boolean = false;


	get followRouteFlag(): boolean {
		return this._followRouteFlag;
	}

	set followRouteFlag(value: boolean) {
		this._followRouteFlag = value;
		vectorSourceProvider.setFollowRoute(value);
	}
	get reverseViewFlag(): boolean {
		return this._reverseViewFlag;
	}

	set reverseViewFlag(value: boolean) {
		this._reverseViewFlag = value;
		if(value){
			this._followRouteFlag = false;
			vectorSourceProvider.setFollowRoute(true);
		}
	}

	private _mapStatic: boolean = true;

	get mapStatic(): boolean {
		return this._mapStatic;
	}

	set mapStatic(value: boolean) {
		this._mapStatic = value;
		if(value ){
			vectorSourceProvider.setFollowRoute(value);
		}

		if(!value && !this.followRouteFlag){
			vectorSourceProvider.setFollowRoute(false);
		}

	};

	getMap(): Map {
		return this.map;
	}

	getVectorLayer(sensorType: string): VectorLayer {
		return this.map.getLayers().getArray().find(layer => layer.get('sensor-type') === sensorType) as VectorLayer;
	}

	getLayerById(layerId: string): BaseLayer[] {
		const layers = this.map.getLayers().getArray();
		return layers.filter(layer => layer.get('id') === layerId);
	}

	getVectorLayerById(layerId: string): VectorLayer {
		const layers = this.map.getLayers().getArray();
		return layers.find(layer => layer.get('id') === layerId);
	}

	getFeatureById(layerId: string, featureId: string): Feature<Geometry> {
		const layers = this.map.getLayers().getArray();
		const vector = layers.find(layer => layer.get('id') === layerId);
		if (vector) {
			const source = vector.getSource();
			if (source) {
				return source.getFeatureById(`${layerId}.${featureId}`);
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	getVisibleVectorSources(vectorLayers: VectorLayer[]): VectorSource[] {
		return vectorLayers.map(layer => layer.getSource());
	}

	deleteLayer(layerId: string): boolean {
		const layer = this.getVectorLayerById(layerId);
		if (!layer) {
			return false;
		}
		return this.map.removeLayer(layer) !== undefined;
	}

	clearSelection(){
		const select = this.select;
		if (select) {
			select.getFeatures().clear();
		}
	}

	deleteFeature(layerId: string, featureId: string): boolean {
		// Clear current selection
		this.clearSelection();
		// Remove feature from layer
		const layer = this.getVectorLayerById(layerId);
		if (!layer) {
			return false;
		}
		const source = layer.getSource();
		if (!source) {
			return false;
		}

		if (!source) {
			return false;
		}
		const feature = source.getFeatureById(`${layerId}.${featureId}`);
		if (!feature) {
			return false;
		}
		source.removeFeature(feature);

		return true;
	};

	showMapLayer(layerId: string) {

		const layerItems = this.getLayerById(layerId);

		if (!layerItems.length) {
			const tileLayers = mapSourceProvider.provideLayers(layerId);
			tileLayers.forEach(tileLayer => {
				this.map.addLayer(tileLayer);
			});
		}
		else {
			layerItems.forEach(layer => layer.setVisible(true));
		}
	};

	showAdasLayer(layerId: string, isVisible = true) {

		const layer = this.getVectorLayerById(layerId);

		if (!layer) {
			const wfsVectorSource: VectorSource = adasLayersService.getWfsVectorSource(layerId);
			const wfsVectorLayer: VectorLayer = adasLayersService.getWfsVectorLayer(layerId, wfsVectorSource);
			wfsVectorLayer.setVisible(isVisible);
			this.map.addLayer(wfsVectorLayer);
		}
		else {
			layer.setVisible(isVisible);
		}
	};

	hideLayer(layerId: string) {

		const layerItems = this.getLayerById(layerId);

		if (layerItems.length > 0) {
			layerItems.forEach(layer => layer.setVisible(false));
		}
	};

	addInteraction(interaction: any) {
		this.map.addInteraction(interaction);
	}

	getInteraction(interactionType) {
		return this.map.getInteractions().getArray().find(x => x instanceof interactionType);
	}

	removeInteraction(interaction: any) {
		this.map.removeInteraction(interaction);
	}

	createMap({ layers, target, center, zoom }): Map {
		this.select = new Select({
			filter: (feature) => !!feature.values_.category,
			style: selectedFeatureStyle
		});
		this.multiSelect = new Select({
			condition: ()=>{},
			style: selectedFeatureStyle
		});
		this.map = new Map({
			target,
			layers:[...layers, ...mineceptVectorLayerProvider.getLayers()],
			loadTilesWhileAnimating: true,
			loadTilesWhileInteracting: true,
			view: new View({
				center,
				zoom
			}),
			controls: []
		});
		this.map.addInteraction(this.select);
		this.map.addInteraction(this.multiSelect);

		this.select.on('select', (e)=>featureSelected(e,this.map));

		setInterval(() => {
			this.map.updateSize(this.map.getSize());
		}, 1000);

		this.map.on('postrender', () => {
			const view = this.map.getView();
			if(this.mapStatic || this.reverseViewFlag){
				return;
			}

			const centerPoint = interpolationService.interpolatedCenter() as number[];
			const mapSize = this.map.getSize() || [0,0];
			if (centerPoint) {
				const center = [centerPoint[0], centerPoint[1]] as Coords ;
				if (!this.followRouteFlag) {
					view.setRotation(-1 * centerPoint[2]);
					const centerWithHeading = interpolationService.getCenterWithHeading(mapSize, centerPoint,centerPoint[2],view.getResolution(),this.reverseViewFlag);
					view.setCenter(centerWithHeading);
				}else {
					view.setCenter(center);
				}
			}
		});

		return this.map;
	};

	getCenter(){
		return this.map.getView().getCenter();
	}

	setCenter(center: Coords){
		if(!this.map) return;
		this.map.getView().setCenter(center);
	};

	setZoom(zoom: number) {
		if(!this.map) return;
		this.map.getView().setZoom(zoom);
	};

	setResolution(resolution: number){
		const view = this.map.getView();
		view.setResolution(resolution);
	};

	generateSources(sensors: IRegisterSensorState[]) {
		sensors.forEach((sensor) => {
			const source = vectorSourceProvider.provide(sensor);
			if(!source) return;

			const layer = new VectorLayer({
				source: source.getSource(),
				style: source.getVectorStyle(),
				visible: sensor.visible === 'true'
			} as any);

			layer.set('sensor-type', sensor.sensorType);
			layer.setZIndex(999);
			this.map.addLayer(layer);
		});
	};

	toggleLayerVisibility(sensorType: string, visibility: boolean): void {
		const layer = this.getVectorLayer(sensorType);
		if (layer) {
			layer.setVisible(visibility);
		}
	};

	clearRotation() {
		this.map.getView().setRotation(0);
	};

	getResolution(){
		const zoom = this.map.getView().getZoom()
		return this.map.getView().getResolutionForZoom(zoom);
	}

	setMapInteractionsActive(active: boolean){
		const interactions = this.map.getInteractions();
		interactions.forEach(interaction=>{
			interaction.setActive(active);
		});
	}

	selectFeature(id){
		if (this.select) {
			let feature = mineceptVectorLayerProvider.getFeatureById(MineceptLayers.hazards, id);
			if (!feature) {
				feature = mineceptVectorLayerProvider.getFeatureById(MineceptLayers.safetyEvents, id);
			}
			if (feature) {
				const features = this.select.getFeatures();
				features.clear();
				features.push(feature);
			}
		}
	}

	multiSelectFeatures(ids){
		if (this.multiSelect) {
			const mapFeatures = this.multiSelect.getFeatures();
			mapFeatures.clear();
			ids.forEach( id=> {
				let feature = mineceptVectorLayerProvider.getFeatureById(MineceptLayers.hazards, id);
				if (feature) {
					mapFeatures.push(feature);
					return;
				}
				feature = mineceptVectorLayerProvider.getFeatureById(MineceptLayers.safetyEvents, id);
				if (feature) {
					mapFeatures.push(feature);
				}
			});
		}
	}

	getBoxFromMap(callback){
		const draw = new Draw({
			type: 'Circle',
			style: drawLayerStyle,
			geometryFunction: createBox(),
		});
		draw.on('drawend',(event)=>{
			const extent = event.feature.getGeometry().getExtent();
			const convertedExtent = convertExtentWebMercatorToWgs84(extent);
			this.map.removeInteraction(draw);
			callback(convertedExtent);
		})
		this.map.addInteraction(draw);
	}
}

export default new OlMapService();
