import YAML from 'yamljs';
import Axios from 'axios';
import generalConfig from '../defaultConfigs/config.json';
import soundList from '../defaultConfigs/soundList.json';
import importanceMatrix from '../defaultConfigs/importanceMatrix.json';
import hazardAndSafetyIcons from '../defaultConfigs/hazardAndSafetyIcons.json';
import hazardAndSafetyLabels from '../defaultConfigs/hazardAndSafetyLabels.json';
import hazardAndSafetyColors from '../defaultConfigs/hazardAndSafetyColors.json';
import vehiclesIcons from '../defaultConfigs/vehiclesIcons.json';
import filters from '../defaultConfigs/filters.json';
import {store} from "../index";
import {setInitFilters} from "../store/actions/mining.actions";
import {IConfig} from "../interfaces/Configs";

const publicFolder = process.env.PUBLIC_URL;
const configPath = `${publicFolder}/config/`;

export const config: IConfig = {
	isInitialized: false,
	isError: false,
	general: generalConfig,
	soundList: { sounds: {}, soundsToNotCut:[] },
	importanceMatrix: undefined,
	hazardAndSafetyIcons: undefined,
	hazardAndSafetyLabels: { hazards: [], safeties: [] },
	hazardAndSafetyColors: { hazards: [], safeties: [] },
	isRequiredLogin: false,
	isMultiSite: false,
	siteKey: undefined,
	vehiclesIcons: undefined,
	filters: { hazards: {}, safetyEvents: {}}
};

const loadConfig = async (configKey: string, filename: string, type: string, defaultConfig: any) => {
	try {
		const [extension, parser] =
			type === 'yaml' ? ['yaml', YAML.parse] : ['json', (res: any) => res];
		const path = `${configPath}${filename}.${extension}`;
		const { data } = await Axios.get(path);
		if (!data) {
			throw new Error("failed to fetch");
		}
		config[configKey] = { ...defaultConfig,  ...parser(data) };
	} catch (e) {
		console.log(`Error loading config of ${configKey}, reason:`, e);
		config.isError = true;
		config[configKey] = { ...defaultConfig };
	}
	Object.seal(config[configKey]);
	Object.freeze(config[configKey]);
};

export const initConfig = async () => {
	const startTime = Date.now();
	const configs: Array<[string, string, string, any]> = [
		['general', 'config', 'yaml', generalConfig],
		['soundList', 'soundList', 'json', soundList],
		['importanceMatrix', 'importanceMatrix', 'json', importanceMatrix],
		['hazardAndSafetyIcons', 'hazardAndSafetyIcons', 'json', hazardAndSafetyIcons],
		['hazardAndSafetyLabels', 'hazardAndSafetyLabels', 'json', hazardAndSafetyLabels],
		['hazardAndSafetyColors', 'hazardAndSafetyColors', 'json', hazardAndSafetyColors],
		['vehiclesIcons', 'vehiclesIcons', 'json', vehiclesIcons],
		['filters', 'filters', 'json', filters]
	];
	// @ts-ignore
	await Promise.allSettled(configs.map(async conf => loadConfig(...conf)));
	config.isRequiredLogin = process.env.REACT_APP_REQUIRE_LOGIN ? 
		process.env.REACT_APP_REQUIRE_LOGIN.trim().toLowerCase() === 'true' : false;
	config.isMultiSite = process.env.REACT_APP_IS_MULTI_SITE ? 
		process.env.REACT_APP_IS_MULTI_SITE.trim().toLowerCase() === 'true' : false;
	config.siteKey = process.env.REACT_APP_SITE_KEY;
	config.isInitialized = true;
	Object.seal(config);
	Object.freeze(config);
	const totalTimeSec = (Date.now() - startTime) / 1000;
	console.debug(`Initializing config took: ${(Math.round(totalTimeSec * 100) / 100)} seconds`);
	store.dispatch(setInitFilters(config.filters))
};
