import {getSafetyEvents} from "./rest";
import {setIsMoreSafetyEvents, setSafetyEvents} from "../store/actions/mining.actions";
import {store} from "../index";


export const updateSafetyEventsByParams = async (filter) => {
    await getSafetyEvents(filter).then(d => store.dispatch(setSafetyEvents(d)));
};

export const isShowMoreSafetyEventsByParams = async (filter) => {
    await getSafetyEvents(filter).then(d => store.dispatch(setIsMoreSafetyEvents(d.length)));
};

export const isEventMatchFilter = (event, filters) => {
    const {type, severity, startTime, filterStart, filterEnd, endTime, archive, filterVehicles, vehicles, filterHpr} = filters;
    const isTypeMatch = type.includes(event.type_of_event);
    const isSeverityMatch = severity.includes(event.severity);
    const isStartDateMatch = !filterStart || (Math.floor(startTime.getTime()/1000) <= event.creation_time);
    const isEndDateMatch = !filterEnd || (Math.floor(endTime.getTime()/1000) >= event.creation_time);
    const isArchiveMatch = archive || event.is_active;
    const isVehicleMatch = !filterVehicles || vehicles.includes(x => x === event.created_by);
    const isHprMatch = !filterHpr || event.hpr_indication;
    return isTypeMatch && isSeverityMatch && isStartDateMatch && isEndDateMatch && isArchiveMatch && isVehicleMatch && isHprMatch;
};

export const getMatchingEvents = (events, filters) => {
    return events.filter( event => isEventMatchFilter(event,filters));
};
