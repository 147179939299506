export const getQueryWithFilters = (query, safety_events_filters, hazards_filters) => {
    getSafetyQueryWithFilters(query, safety_events_filters);
    getHazardQueryWithFilters(query, hazards_filters);
    return query;
};
const getSafetyQueryWithFilters = (query, filters) => {
    query.safety_event_typeList = JSON.stringify(filters.type);
    query.safety_event_severity = JSON.stringify(filters.severity);
    query.safety_event_filterStart = JSON.stringify(filters.filterStart);
    query.safety_event_startTime = (filters.startTime).toDateString();
    query.safety_event_filterEnd = JSON.stringify(filters.filterEnd);
    query.safety_event_endTime = (filters.endTime).toDateString();
    query.safety_event_archive = JSON.stringify(filters.archive);
    query.safety_event_filterVehicles = JSON.stringify(filters.filterVehicles);
    query.safety_event_vehicles = JSON.stringify(filters.vehicles);
    query.safety_event_filterHpr = JSON.stringify(filters.filterHpr);
    query.safety_event_chunk = JSON.stringify(filters.chunk);
    query.safety_event_chunk_size = JSON.stringify(filters.chunkSize);
    return query;
};

const getHazardQueryWithFilters = (query, filters) => {
    query.hazard_typeList = JSON.stringify(filters.type);
    query.hazard_severity = JSON.stringify(filters.severity);
    query.hazard_filterStart = JSON.stringify(filters.filterStart);
    query.hazard_startTime = (filters.startTime).toDateString();
    query.hazard_filterEnd = JSON.stringify(filters.filterEnd);
    query.hazard_endTime = (filters.endTime).toDateString();
    query.hazard_archive = JSON.stringify(filters.archive);
    query.hazard_filterVehicles = JSON.stringify(filters.filterVehicles);
    query.hazard_vehicles = JSON.stringify(filters.vehicles);
    query.hazard_chunk = JSON.stringify(filters.chunk);
    query.hazard_chunk_size = JSON.stringify(filters.chunkSize);
    return query;
}
