let timeoutInterval;

export const debounce = (callback,timeout) => ()=>{
    clearTimeout(timeoutInterval);
    timeoutInterval = setTimeout(
        ()=>{
        callback();
        }
        ,timeout*1000);
}
