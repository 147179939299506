import logo from '../../../../assets/images/logo.png';
import React from 'react';
import profileConfig from '../../../../core/profiles/profileConfig';
import useStyles from './logo.css';
import { keycloakService } from '../../../../services/keycloak';

const Logo = ()=>{
	const classes = useStyles();
	const showClientLogo = profileConfig().showClientLogo;
	return(
	<div className={classes.root}>
		{
			showClientLogo && <img src={'./clientLogo.png'} className={classes.clientLogo}/>
		}
		<img src={logo} className={classes.ceptionLogo}/>
		<div className={classes.version} >{process.env.REACT_APP_APPLICATION_VERSION}</div>
		{
			keycloakService.isAuthenticated() &&
			<div className={classes.logout} onClick={() => keycloakService.logout()}>Logout</div>
		}
	</div>
	);
};

export default Logo;
