import {makeStyles} from '@material-ui/core/styles';

interface Props {
    display: boolean
    archive: boolean
}

export default makeStyles({
    root: {
        zIndex: 1,
        backgroundColor: '#3a475d',
        borderRadius: 6,
        position: 'absolute',
        left: 0,
        top: 40,
        width: 270,
        color: 'white',
        fontFamily: 'Mukta Mahee',
        fontSize: 16,
    },

    titleBar: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 9,
    },
    closeButton: {
        width: 14,
        height: 14
    },
    filterSection: {
        // borderBottom: '1px solid white',
        margin: '15px 0',
        position: 'relative',
    },
    sectionTitle: {
        marginLeft: 13,
    },
    formControl: {
        margin: 10,
        minWidth: 160,
        maxWidth: 160,
        color: 'white',
    },
    formControlType: {
        minWidth: 180,
        maxWidth: 180,
    },
    formControlVehicle: {
        minWidth: 115,
        maxWidth: 115,
    },
    inputRow: {
        display: 'flex',
        alignItems: 'center',
    },
    label: {
        marginRight: 10
    },
    apply: {
        display: 'flex',
        justifyContent: 'center',
        width: 99,
        height: 29,
        color: ' white',
        backgroundColor: '#1a2538',
        fontSize: 16,
        fontWeight: 'normal',
        borderRadius: 13.5,
    },
    cancel: {
        display: 'flex',
        justifyContent: 'center',
        width: 99,
        height: 29,
        color: ' white',
        backgroundColor: '#307DFF',
        fontSize: 16,
        fontWeight: 'normal',
        borderRadius: 13.5,
    },
    separatorLine: {
        height: 0,
        margin: 25,
        borderBottom: 'solid 1px rgba(255, 255, 255, 0.2)',
    },
    datePicker: {
        // width:250
        color: 'white',
        fontSize: 14,
        fontWeight: 'normal'
    },
    datePickerCalendar: {
        color: 'white',
    },
    select: {
        color: 'white',
        fontSize: 14,
        icon: {
            color: 'white'
        },
    },
    selectText: {
        fontSize: 14,
        color: 'white',
        fontFamily: 'Mukta Mahee',
    },
    archiveOrHpr: {
        display: 'flex',
        gap: 3,
        padding: '3px 3px 3px 0px',
        height: 32,
        color: 'white',
    },
    controls: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '20px 22px 21px 22px'
    },
    archiveIcon: {
        width: '20px',
        height: '20px'
    },
    underline: {
        borderBottom: 'solid 1px rgba(255, 255, 255, 0.2)',
        '&:before': {borderBottom: 'solid 1px rgba(255, 255, 255, 0.2)'},
        '&:after': {borderBottom: 'solid 1px rgba(255, 255, 255, 0.2)'},
        '&:focus': {borderBottom: 'solid 1px rgba(255, 255, 255, 0.2)'},
        borderBottomStyle: "none"
    },
    underlineDate: {
        minWidth: 170,
        maxWidth: 170,
    }
});
