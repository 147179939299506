import {unixToDateFormat} from "../core/auxilary/formatting";
import {FeatureTypes} from "../definition/features";

const featureTypeToText = {
    [FeatureTypes.safetyEvent]: 'safetyEvent',
    [FeatureTypes.hazard]: 'hazard'
}

export const getFileName = (feature, data, selectedSiteName) => {
    const date = unixToDateFormat(feature.creation_time, 'YYYYMMDD_HHmmss');
    const featureType = featureTypeToText[feature.featureType];
    return [date, featureType, data.label, selectedSiteName]
        .filter(x => Boolean(x)).join('_').replace(/ /g, '_').toLowerCase();
}
