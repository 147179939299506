interface ITimeObject {
    hour: number;
    minute: number;
    second: number;
}

const runTimeObject = (callback: () => void, timeObj: ITimeObject): void => {
    const now = new Date();
    const targetTime = new Date();

    targetTime.setHours(timeObj.hour);
    targetTime.setMinutes(timeObj.minute);
    targetTime.setSeconds(timeObj.second);

    const timeDifference = targetTime.getTime() - now.getTime();

    if (timeDifference <= 0) {
        // The target time has already passed today, so we'll schedule it for tomorrow
        targetTime.setDate(targetTime.getDate() + 1);
    }

    const delay = targetTime.getTime() - new Date().getTime();

    setTimeout(callback, delay);
}

export const runAtSpecificTime = (callback: () => void, timeArray: ITimeObject[]) : void => {
    timeArray.map(timeObj=>{
        runTimeObject(callback, timeObj);
    })
}
