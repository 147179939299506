import axios from "axios";
import {getHeaders} from "../core/services/minecept/mineceptServer";
import {config} from "./config";

const BASE_URL = process.env.REACT_APP_REST_SERVER as string;
const MINECEPT_ROUTE = process.env.REACT_APP_SERVER_MINECEPT_ROUTE as string;
const BASE_ROUTE = `${BASE_URL}/${MINECEPT_ROUTE}/`;

const routes = {
    safetyEvent: `${BASE_ROUTE}safetyevents/`,
    hazard: `${BASE_ROUTE}obstacles/`,
    vehicles: `${BASE_ROUTE}vehicles/`
};

export const getSafetyEvents = async (filters) => {
    const url = routes.safetyEvent;
    const safetyEventMinSeverity = process.env.REACT_APP_SAFETY_EVENT_MINIMUM_SEVERITY_TO_SHOW as string;
    const options = {
        headers: await getHeaders()
        ,params:{
            all: true,
            min_severity: safetyEventMinSeverity || 0,
            type: JSON.stringify(filters.type),
            severity: JSON.stringify(filters.severity),
            startTime: filters.startTime,
            filterStart: (filters.filterStart),
            filterEnd: filters.filterEnd,
            endTime: (filters.endTime),
            archive: filters.archive,
            filterVehicles: filters.filterVehicles,
            vehicles: JSON.stringify(filters.vehicles),
            filterHpr:filters.filterHpr,
            chunk: filters.chunk + 1 || 1,
            chunkSize: filters.chunkSize
        }
    };
    const response = await axios.get(url, options);
    return response.data;

};

export const getHazards = async (filters) => {
    const url = routes.hazard;
    const hazardMinSeverity = process.env.REACT_APP_OBSTACLE_MINIMUM_SEVERITY_TO_SHOW as string;
    const hazardMaxAge = process.env.REACT_APP_OBSTACLE_MAXIMUM_AGE_TO_SHOW_MINUTES as string;
    const options = {
         headers: await getHeaders()
         ,params:{
            all: true,
            hazard_max_age : hazardMaxAge || 24 * 60,
            min_severity: hazardMinSeverity || 0,
            type: JSON.stringify(filters.type),
            severity: JSON.stringify(filters.severity),
            startTime: filters.startTime,
            filterStart: (filters.filterStart),
            filterEnd: filters.filterEnd,
            endTime: (filters.endTime),
            archive: filters.archive,
            filterVehicles: filters.filterVehicles,
            vehicles: JSON.stringify(filters.vehicles),
            chunk: filters.chunk + 1 || 1,
            chunkSize: filters.chunkSize
        }
    };

    const response = await axios.get(url, options);
    return response.data;

};

export const getVehicles = async () => {
    const url = routes.vehicles;
    const maxAge = config.general.maxAgeOfActiveVehicles;
    const options = {
        headers: await getHeaders()
        ,params:{
            maxAge: maxAge || 90
        }
    };
    const response = await axios.get(url, options);
    return response.data;

};
