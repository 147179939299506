import {makeStyles} from '@material-ui/core/styles';

interface IVideoSize {
    width: number;
    height: number;
}

interface IProps {
    videoSize?: IVideoSize,
    miniMapSize: number,
    isFullScreen: boolean;
    isHoverVideo: boolean;
}

const getProportionalSize = (isWidth: boolean, videoSize?: IVideoSize, margin: number = 0, proportion: number = 1) => {
    if (!videoSize) {
        return `calc(${proportion * 100}% + ${margin}px)`;
    }
    const isWidthBigger = (videoSize.width > videoSize.height);
    const docSize = isWidthBigger ? document.body.scrollWidth : document.body.scrollHeight;
    let length: number;
    if (isWidthBigger) {
        if (isWidth) {
            length = docSize * proportion + margin;
        } else {
            length = docSize * (videoSize.height / videoSize.width) * proportion + margin;
        }
    } else {
        if (isWidth) {
            length = docSize * (videoSize.width / videoSize.height) * proportion + margin;
        } else {
            length = docSize * proportion + margin;
        }
    }
    return `${length}px`;
}

export default makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10000,
        position: 'absolute',
        background: 'transparent',
        pointerEvents: 'none'
    },
    containerShown: {
        // background: '#80808080',
    },
    containerFull: {
        width: '100%',
        height: '100%',
        background: 'black'
    },
    containerMinimized: (props: IProps) => ({
        width: getProportionalSize(true, props.videoSize, 0, 0.45),
        height: getProportionalSize(false, props.videoSize, 0, 0.45),
        left: `calc(100% - ${getProportionalSize(true, props.videoSize, 20, 0.45)} - ${Math.floor(props.miniMapSize)}px)`,
        top: `calc(100% - ${getProportionalSize(false, props.videoSize, 20, 0.45)})`
    }),
    playerContainer: {
        width: '100%',
        height: '100%',
        pointerEvents: 'all',
        display: 'flex'
    },
    player: (props: IProps) => ({
        width: props.isFullScreen ? `calc(100% - ${props.miniMapSize * 2}px)` : '100%',
        height: '100%',
        flexGrow: 1,
        outline: 'none',
    }),
    toolbar:  (props: IProps) => ({
        display: 'flex',
        flexDirection: 'row',
        position: 'fixed',
        padding: '3px',
        background: '#25334B',
        zIndex: 100,
        PointerEvents: 'all',
        opacity: 0.7,
        width: props.isHoverVideo ? (props.isFullScreen ? '100%' : getProportionalSize(true, props.videoSize, 0, 0.45)) : 'fit-content',
    }),
    fileName: {
        padding: '8px 0 0 10px',
        color: 'white',
        overflow: 'hidden',
        width:'75%',
        fontSize: '14px',
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30px',
        height: '30px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'white',
        borderRadius: '18px',
        cursor: 'pointer',
        padding: '3px',
        margin: '3px'
    },
    miniMap: {
        position: 'relative',
        alignSelf: 'flex-end',
        display: 'flex',
        alignItems: 'center',
        zIndex: 501,
    },
    icon: {
        color: 'white',
        fontSize: '1rem'
    },
    hidePlayer: {
        display: 'none'
    }
});
