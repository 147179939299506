import React, { useEffect} from 'react';
import List from '@material-ui/core/List';
import useStyles from './SideTable.css';
import { useDispatch, useSelector } from 'react-redux';
import closeImage from '../../assets/images/clear.svg';
import { SortBy } from '../../definition/Enums';
import TableListItem from './tableListItem/TableListItem';
import SortFilterHeader from './sortFilterHeader/SortFilterHeader';
import Emitter from '../../emitter';
import {ListItem} from "@material-ui/core";


interface Iprops{
	display: boolean
	close: ()=>void
	labels:{
		title: string
		sortTitle:string
		filterTitle: string
		typeLabels: Array<string>
		sortOptions: Array<{label: string, value:SortBy}>
	}
	selectors:{
		data: any
		controls: any
		filter: any
		existingTypes: any
		filtersActivated: any
		areAllSelected: any
		multiSelected: any
		vehicles: any
		more: any
	}
	actions:{
		setSort: any
		removeNewMessageAlert: any
		clearFilters: ()=>any
		setFilters: any
		selectAll: any
		unSelectAll: any
		clearMultiSelect: any
		archiveMulti: any
		restoreMulti: any
		showMoreEntities: any
	}
}

const closeSubMenus = () => {
	Emitter.emit('closeSubMenus');
}

function SideTable({display, close, labels, selectors, actions}:Iprops) {
	const data = useSelector(selectors.data);
	const dispatch = useDispatch();
	const controls = useSelector(selectors.controls);
	const more = useSelector(selectors.more);
	const filters = useSelector(selectors.filter);
	// const isMultiSelected = useSelector(getIsMultiSelected);

	const archive = controls.archive;
	const classes = useStyles({display, archive});

	const onClickMore =  async() => {
		dispatch(actions.showMoreEntities(filters));
	}

	useEffect(()=>{
		dispatch(actions.removeNewMessageAlert);
	},[display]);

	return (
		<div className={classes.root}>
			<div className={classes.titleBar} onClick={closeSubMenus}>
				<span>{labels.title}</span>
				<span onClick={close} className={classes.closeButton}><img className={classes.closeSymbol} src={ closeImage }/></span>
			</div>
			<SortFilterHeader {...{display, labels, selectors, actions}}/>
			<List className={classes.list}>
				{
					data.map((item)=><TableListItem {...item} key={item.id}/>)
				}
				{
					more && <ListItem>
						<div className={classes.loadMore} onClick={onClickMore}>
							Load More
						</div>
					</ListItem>
				}
			</List>
		</div>
	);
}

export default SideTable;
