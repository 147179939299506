import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
	listItem:{
		padding: '12px 20px',
		display: 'grid',
		gridTemplateColumns: '40px auto'
	},
	listItemSelected:{
		padding: '12px 20px',
		display: 'grid',
		gridTemplateColumns: '40px auto',
		background: '#131E30',
	},
	archivedIcon:{
		marginLeft: '8px',
		width: '12px',
		height: '12px'
	},
	hprIcon:{
		marginLeft: '10px',
		width: '60px',
		height: '30px',
		marginTop: '-4px'
	},
	label:{
		gridColumn:"2 / 3",
		gridRow: "1 / 2",
		fontSize: 15,
		fontWeight: 'bold'
	},
	date:{
		gridColumn:"2 / 3",
		gridRow: "2 / 3",
		fontSize: 15,
	},
	dateWithHpr:{
		gridColumn:"2 / 3",
		gridRow: "2 / 3",
		fontSize: 15,
		display: 'inline-flex'
	},
	symbol:{
		pointerEvents: "none",
		width: 43,
		height: 43,
		padding: 9,
	},
	symbolSection:{
		gridColumn:"1 / 2",
		gridRow: "1 / 3",
		width: 49,
		height: 49,
	},
	checkbox:{
		pointerEvents: "none",
	}
});
