import React from 'react';
import useStyles from './NoSitesPage.css';
import profileConfig from '../../core/profiles/profileConfig';
import logo from '../../assets/images/logo.png';
import { Button } from '@material-ui/core';
import { useKeycloak } from "@react-keycloak/web";

const NoSitesPage = () => {
	const classes = useStyles();
    const { keycloak } = useKeycloak();

    const handleLogout = () => {
        keycloak.logout();
    };

	return (
		<div className={classes.root}>
			<div className={classes.loginSection}>
				<div className={classes.title}>No sites permitted</div>
                <div className={classes.title}>Please contact administrator</div>
                <Button className={classes.logoutButton} onClick={handleLogout}>Logout</Button>
			</div>
			<img height={150} src={logo} className={classes.logo} alt=''/>
			{profileConfig().showClientLogo && <img height={150} src={'./clientLogo.png'} className={classes.clientLogo} alt=''/>}
		</div>
	);
};

export default NoSitesPage;
